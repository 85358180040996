.router-page {
    .subtitle {
        display: flex;
        margin-top: 0.625rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #7159c1;
        }
    }
}

.home {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .homeSection, .aboutMeSection {
        display: flex;
        flex-direction: column;
        margin: 0.625rem 0 1.2rem 0;
    }

    .aboutMeSection {
        max-width: 60vw;
        @media screen and (max-width: 500px) {
            max-width: 90vw;
            p {
                max-width: 90vw;
            }
        }
    }

    .homeSection h1 {
        font-size: 1.6rem;
    }

    .role {
        color: #5960c1;
        font-weight: 700;
    }

    p {
        margin-top: 10px;
        font-size: 1.25rem;
        line-height: 2;
        max-width: 60vw;
    }

    a {
        font-size: 14px;
        text-decoration: none;
        font-weight: bold;
        margin-top: 10px;
    }

    .myImg {
        width: 250px;
        height: 250px;
        margin: 20px 0;
        border-radius: 50%;
        border: 4px solid rgba(219, 211, 249, 0.492);
        box-shadow: rgba(52, 52, 52, 0.24) 0px 3px 8px;
    }

    .social-media {
        display: flex;
        flex-direction: row;
        .social-link {
            margin: 0 0.7rem;
        }
        @media screen and (max-width: 500px) {
            flex-direction: column;
            .social-link {
                margin: 1rem 0;
            }
        }
    }

    .skills {
        max-width: 60vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        @media screen and (max-width: 500px) {
            max-width: 90vw;
        }
        .skill {
            border: 3px solid rgb(224, 219, 243);
            border-radius: 20%;
            margin: 0.6rem 0.3rem;
            padding: 0.5rem 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: translateY(-1em);
            }
            h1 {
                font-size: 1.25rem;
                margin: 0;
            }
            img {
                width: 20px;
                height: 20px;
                margin: 5px;
            }
        }
    }
}

.resume {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: 0 auto;
    width: 60vw;
    @media only screen and (max-width: 1200px) {
        width: 90vw;
    }
    h2,
    h3 {
        margin-top: 1rem;
    }

    a {
        text-decoration: none;
    }
    .resumeButton {
        margin: 2rem;
        padding: 0.23rem;
        border-radius: 5px;
        background: linear-gradient(
            47deg,
            #421eba 0%,
            #7159c1 50%),
            #421eba 100%;
        color: white;
        font-size: 1.25rem;
        transition: all 0.35s ease-in-out;
        &:hover {
            background-position: 247px;
        }
        button {
            margin: 0;
        }
        svg {
            margin-bottom: 0.25rem;
        }
    }

    .summary {
        max-width: 60vw;
        line-height: 2;
        font-size: 1.25rem;
        @media only screen and (max-width: 1200px) {
            max-width: 90vw;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }
    td {
        padding: 0.5rem;
    }

    .experience-location {
        margin-right: 4rem;
    }
}

.projectPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        margin-top: 2rem;
        margin-left: 2rem;
    }
}
